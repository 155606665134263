<template>
  <header class="header padding">
    <div class="container">
      <div class="header__content">
        <a href="/" class="header__logo" aria-label="link to main page">
            <img src="@/images/new-logo.svg" alt="logo" />
        </a>
        <!-- <router-link to="/" class="header__logo" aria-label="link to main page">
            <img src="@/images/new-logo.svg" alt="logo" />
        </router-link> -->
        <nav class="header__navigation" v-show="!isLogin">
          <router-link :to="pricingLink" class="header__link link_pricing">Pricing</router-link>
          <router-link to="/sign-in" class="header__link link_sign-in">Sign in</router-link>
        </nav>
        <nav class="header__navigation header__navigation_login" v-show="isLogin">
          <router-link to="/palettes" class="header__link link_pricing header__link_login" v-show="!isExpired">My palettes</router-link>
          <router-link to="/profile" class="header__link link_pricing header__link_login" >My profile</router-link>
        </nav>
      </div>
    </div>
  </header>
</template>

<script>
import { useStore } from "vuex";

export default {
  data() {
    return {
    }
  },
  setup() {
    const store = useStore();
    let isLogin = Boolean(store.state.userToken);
    let isExpired = store.state.user ? store.state.user.expired : false
    return {
      isLogin,
      isExpired
    };
  },
  computed: {
    pricingLink() {
      const withoutPricingSection = ['not-found', '/help', '/install-ff', '/privacy', '/tos']
      return withoutPricingSection.includes(this.$route.path) ? '/#pricing' : '#pricing';
    }
  }
}
</script>


<style scoped>
.padding {
  padding: 0 20px;
}

.container {
  width: 100%;
  max-width: 1224px;
  margin: 0 auto;
}

.header {
  background-color: #F5F8FA;
}

.header__content {
  box-sizing: border-box;
  padding: 22px 0 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header__logo {
  width: 190px;
  height: 26px;
}

.header__logo:hover {
  opacity: 0.8;
}

.header__logo img {
  width: 100%;
}

.header__navigation {
  display: flex;
  column-gap: 50px;
}

.header__navigation_login {
  column-gap: 28px;
}

.header__link {
  color: #3080F2;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  box-sizing: border-box;
  padding: 0 25px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
}

.header__link_login {
  padding: 2px 10px 0;
}

.link_sign-in {
  background-color: #FFFFFF;
  color: #7693FC;
  border: 1px solid #7693FC;
  border-radius: 10px;
}

.link_sign-in:hover {
  opacity: 0.8;
}

.link_pricing:hover {
  opacity: 0.8;
}

.link_pricing:active {
  opacity: 1;
}

@media screen and (max-width: 900px) {
  .header__navigation {
    column-gap: 40px;
  }
}

@media screen and (max-width: 600px) {
  .padding {
    padding: 0 16px;
  }
  .link_pricing {
    display: none;
  }
  .header__logo {
    width: 170px;
    height: 24px;
  }
  .header__content {
    padding: 15px 0 0;
  }
  .header__link_login:last-of-type{
    display: flex;
  }
}
</style>
