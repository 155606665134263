<template>
  <section class="landing-page">
    <Header />
    <MainInfo currentCard="Extract Colors DevTool" />
    <Pricing />
    <ProductHunt />
    <Footer />

    <section class="hunt"></section>

    <footer class="footer"></footer>
  </section>
</template>

<script>
import Header from "@/components/landing/Header";
import MainInfo from "@/components/comparison/MainInfo";
import Pricing from "@/components/landing/Pricing";
import ProductHunt from "@/components/landing/ProductHunt";
import Footer from "@/components/landing/Footer";

export default {
  components: {
    Header,
    MainInfo,
    Pricing,
    ProductHunt,
    Footer,
  },
  mounted() {
    const title = 'Site Palette vs. Extract Colors DevTool: Why Site Palette Leads with Advanced Features';
    const description = 'Discover how Site Palette outperforms Extract Colors DevTool with its superior palette exporting, profile management, and seamless integration with design tools, offering a more efficient workflow for designers.';

    document.title = title;
    this.updateMetaTag('description', description);
    this.updateMetaTag('twitter:title', title);
    this.updateMetaTag('twitter:description', description);
    this.updateMetaTag('title:title', title);
    this.updateMetaTag('title:description', description);
  },
  methods: {
    updateMetaTag(name, content) {
      let metaTag = document.querySelector(`meta[name="${name}"], meta[property="${name}"]`);
      if (!metaTag) {
        metaTag = document.createElement('meta');
        if (name.startsWith('og:') || name.startsWith('twitter:')) {
          metaTag.setAttribute('property', name);
        } else {
          metaTag.setAttribute('name', name);
        }
        document.head.appendChild(metaTag);
      }
      metaTag.setAttribute('content', content);
    },
  },
};
</script>
<style scoped>

* {
  color: #3d3d3d;
}

.landing-page {
  width: 100%;
  box-sizing: border-box;
}

</style>
