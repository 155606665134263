<template>
  <section class="help-page">
    <Header />
    <Main />
    <Footer />
  </section>
</template>

<script>
import Header from "@/components/landing/Header";
import Main from "@/components/installFf/Main";
import Footer from "@/components/landing/Footer";

export default {
  components: {
    Header,
    Main,
    Footer,
  },
  mounted() {
    const title = 'Effortless Firefox Integration: Enable Required Permissions for Proper Extension Functionality';
    const description = 'To ensure smooth operation of the extension, follow these steps to enable the "Access your data for all websites" permission in Firefox. This step is essential for the extension to function properly across all sites.';

    document.title = title;
    this.updateMetaTag('description', description);
    this.updateMetaTag('twitter:title', title);
    this.updateMetaTag('twitter:description', description);
    this.updateMetaTag('title:title', title);
    this.updateMetaTag('title:description', description);
  },
  methods: {
    updateMetaTag(name, content) {
      let metaTag = document.querySelector(`meta[name="${name}"], meta[property="${name}"]`);
      if (!metaTag) {
        metaTag = document.createElement('meta');
        if (name.startsWith('og:') || name.startsWith('twitter:')) {
          metaTag.setAttribute('property', name);
        } else {
          metaTag.setAttribute('name', name);
        }
        document.head.appendChild(metaTag);
      }
      metaTag.setAttribute('content', content);
    },
  },
};
</script>
<style scoped>

* {
  color: #3d3d3d;
}

.help-page {
  width: 100%;
  box-sizing: border-box;
}

</style>
