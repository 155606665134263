<template>
  <section class="welcome">
    <header class="header padding">
      <div class="container">
        <div class="header__content">
          <router-link to="/" class="header__logo">
            <img src="@/images/logo-white.svg" />
          </router-link>

          <nav class="header__navigation">
            <router-link
              :to="myPalettePath"
              class="header__link"
              v-show="!user.expired"
              >My palettes</router-link
            >
            <router-link to="/profile" class="header__link"
              >My profile</router-link
            >
          </nav>
        </div>
      </div>
    </header>

    <section class="how-it-works padding">
      <div class="container">
        <div class="how-it-works__content">
          <h2 class="how-it-works__title">{{ welcomeTitle }}</h2>
          <p class="how-it-works__subtitle" v-show="isPlanUpgraded">
            You successfully upgraded your plan
          </p>
          <div
            class="section-block"
            :class="{ 'section-block_upgrated': isPlanUpgraded }"
          >
            <ul class="cards" :class="{ cards_upgrated: isPlanUpgraded }">
              <li class="card" v-show="!isPlanUpgraded">
                <h3 class="card-title">
                  {{
                    `${
                      1 - Number(isPlanUpgraded)
                    }. Install the extension for your browser`
                  }}
                </h3>
                <div class="card-image">
                  <img
                    src="../images/howit-first-new.svg"
                    alt="install-exstension"
                  />
                </div>
              </li>
              <li class="card">
                <h3 class="card-title">
                  {{
                    `${
                      2 - Number(isPlanUpgraded)
                    }. Click the extension in your browser`
                  }}
                </h3>
                <div class="card-image">
                  <img
                    src="../images/howit-second.png"
                    alt="click-exstension"
                  />
                </div>
              </li>
              <li class="card">
                <h3 class="card-title">
                  {{
                    `${3 - Number(isPlanUpgraded)}. Generate your first palette`
                  }}
                </h3>
                <div class="card-image">
                  <img src="../images/howit-third.png" alt="generate-palette" />
                </div>
              </li>
              <li class="card">
                <h3 class="card-title">
                  {{
                    `${
                      4 - Number(isPlanUpgraded)
                    }. See all your palettes in History`
                  }}
                </h3>
                <div class="card-image">
                  <img src="../images/howit-fourth.png" alt="all-palettes" />
                </div>
              </li>
            </ul>
          </div>
          <p class="how-it-works__disclamen" v-show="!isPlanUpgraded">
            Free trial is available for 24 hours.
          </p>
          <p class="how-it-works__link-ext" v-show="!isPlanUpgraded">
            Still no Site Palette?
            <a class="how-it-works__link-url" :href="btnExtRef" target="_blank"
              >Install</a
            >
            the extension for your browser
          </p>
          <p class="how-it-works__link-ext how-it-works__link-ext_ff" v-show="!isPlanUpgraded && isFirefox">
            For your convenience, follow our quick
            <router-link to="/install-ff" class="how-it-works__link-url">guide</router-link>
          </p>
        </div>
      </div>
    </section>

    <footer class="footer padding">
      <div class="container">
        <div class="footer__content">
          <div class="footer__request">
            <a
              class="footer__link-request"
              href="mailto:support@palette.site?Subject=PRO"
              >✍🏻 &#160;Request a feature</a
            >
          </div>

          <div class="footer__madeby">
            Made by 
            <a class="footer__link" href="https://karmabot.chat/" target="_blank"
              >Karma</a>
            team with ❤️
          </div>

          <div class="footer__terms">
            <router-link to="/privacy" class="footer__link"
              >Privacy policy</router-link
            >
            <router-link to="/tos" class="footer__link"
              >Terms of service</router-link
            >
          </div>
        </div>
      </div>
    </footer>
  </section>
  <Background />
</template>

<script>
import store from "@/store";
import { useStore } from "vuex";
import mainApi from "../utils/MainApi";
import {
  getInfoAboutCurrentPlan,
  getInfoAboutPlanTimeLeft,
} from "../utils/utils";
import Background from "@/components/Background";
import queryString from "query-string";
import { event } from "vue-gtag";

export default {
  components: {
    Background,
  },
  data() {
    let btnExtRef =
      "https://chrome.google.com/webstore/detail/site-palette/pekhihjiehdafocefoimckjpbkegknoh";
    let isFirefox = false;

    switch (store.state.browser) {
      case "safari":
        btnExtRef =
          "https://apps.apple.com/us/app/site-palette/id6451384418?mt=12";
        break;
      case "firefox":
        btnExtRef = "https://addons.mozilla.org/addon/site-palette";
        isFirefox = true;
        break;
      default:
        break;
    }
    return {
      welcomeTitle: "",
      btnExtRef,
      isFirefox
    };
  },
  setup() {
    const store = useStore();
    let user = store.getters.user;
    let myPalettePath = "";
    if (user.expired) {
      myPalettePath = "/expired";
    } else {
      myPalettePath = "/palettes";
    }
    let isPlanUpgraded = Boolean(queryString.parse(location.search).upgraded);
    if (isPlanUpgraded) {
      if (user.planInfo == "Monthly") {
        event("purchase_subscription", {
          event_category: "purchase",
          event_label: "Purchase Subscription",
        });
      } else if (user.planInfo == "Annual") {
        event("purchase_annual", {
          event_category: "purchase",
          event_label: "Purchase Annual",
        });
      } else if (user.planInfo == "Lifetime") {
        event("purchase_lifetime", {
          event_category: "purchase",
          event_label: "Purchase Lifetime",
        });
      }
    }
    return {
      myPalettePath,
      isPlanUpgraded,
      user,
    };
  },
  methods: {},
  beforeRouteEnter(to, from, next) {
    mainApi.getUser(store.getters.userToken).then((data) => {
      data.user.planInfo = getInfoAboutCurrentPlan(data.user);
      data.user.planTimeLeft = getInfoAboutPlanTimeLeft(data.user);
      store.dispatch("setUser", data.user);
      next();
    });
  },
  beforeMount() {
    if (this.isPlanUpgraded) {
      this.welcomeTitle = "Thank you! What is next?";
    } else {
      this.welcomeTitle = "You’ve just signed in";
    }
  },
};
</script>

<style scoped>
.welcome {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: #3350c4;
}

.padding {
  padding: 0 20px;
}

.container {
  width: 100%;
  max-width: 1224px;
  margin: 0 auto;
}

/* HEADER */

.header {
}

.header__content {
  box-sizing: border-box;
  padding: 34px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header__logo {
  width: 172px;
  height: 24px;
  z-index: 2;
  position: relative;
}

.header__logo:hover {
  opacity: 0.8;
}

.header__logo img {
  width: 100%;
}

.header__navigation {
  display: flex;
  column-gap: 28px;
}

.header__link {
  background-color: transparent;
  color: #fff;
  font-weight: 800;
  font-size: 16px;
  line-height: 22px;
  box-sizing: border-box;
  padding: 0 10px;
  height: 42px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 2;
}

.header__link:hover {
  opacity: 0.8;
}

/* HOW IT WORKS */

.how-it-works__content {
  padding: 90px 0 50px;
}

.how-it-works__title {
  font-size: 36px;
  line-height: 38px;
  text-align: center;
  font-weight: 700;
  /*margin-bottom: 20px;*/
  position: relative;
  z-index: 2;
}
.how-it-works__subtitle {
  font-weight: 400;
  font-size: 19px;
  line-height: 26px;
  text-align: center;
  color: #ffffff;
  opacity: 0.5;
  margin: 0;
  padding: 18px 0 31px;
}
.how-it-works__link-ext {
  margin: 24px 0 8px;
  padding: 0;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  text-align: center;
  color: #ffffff;
  position: relative;
  z-index: 100;
}

.how-it-works__link-ext_ff {
  margin-top: 8px;
  font-size: 14px;
  line-height: 20px;
  opacity: 0.5;
}

.how-it-works__link-box {
  display: flex;
  flex-direction: row;
  column-gap: 20px;
  justify-content: center;
  position: relative;
  z-index: 100;
}

.how-it-works__link-url {
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #60a1ff;
  text-decoration: none;
  cursor: pointer;
}

.how-it-works__link-url:hover {
  opacity: 0.8;
}

.section-block {
  margin-top: 75px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  max-width: 1198px;
}

.section-block_upgrated {
  margin: 0 auto;
  max-width: 930px;
}

.cards {
  display: flex;
  justify-content: space-between;
  column-gap: 50px;
  margin: auto;
}

.card {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.card-title {
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  font-weight: 400;
  max-width: 233px;
  margin: 0 auto 18px;
  min-height: 40px;
  position: relative;
  z-index: 2;
}

.card-image {
  width: 100%;
  border-radius: 50%;
  background: #fff;
  box-shadow: 0 4px 24px 0 rgba(0, 0, 0, 0.15);
  overflow: hidden;
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: column;
  width: 262px;
  height: 262px;
  justify-content: center;
  align-items: center;
}

.card-image__title {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: rgba(0, 0, 0, 0.5);
  margin: 0 0 63px;
  padding: 0;
}

.card img {
  width: 100%;
}

.how-it-works__disclamen {
  margin: 40px auto 0;
  text-align: center;
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  color: #fff;
  opacity: 0.5;
}

@media screen and (max-width: 1280px) {
  .cards {
    column-gap: 25px;
  }
}

@media screen and (max-width: 1144px) {
  .cards {
    gap: 25px;
    flex-wrap: wrap;
    max-width: 574px;
  }
  .cards_upgrated {
    max-width: 930px;
  }
}

@media screen and (max-width: 900px) {
  .cards_upgrated {
    max-width: 574px;
    justify-content: center;
  }
}

@media screen and (max-width: 800px) {
  .how-it-works__title {
    font-size: 35px;
    line-height: 35px;
  }

  .how-it-works__subtitle {
    font-size: 17px;
    line-height: 21px;
  }

  .padding {
    padding: 0 8px;
  }
}

@media screen and (max-width: 600px) {
  .cards {
    max-width: 262px;
  }
  .header__content {
    flex-direction: column;
    row-gap: 46px;
  }
  .how-it-works__content {
    padding: 50px 0 50px;
  }
}

@media screen and (max-width: 450px) {
  .card-image {
    width: 265px;
    height: 265px;
    background-size: cover;
  }
  .section-block {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  .cards {
    width: 100%;
    min-width: 335px;
    flex-wrap: wrap;
    margin: 00;
    row-gap: 40px;
    column-gap: 30px;
    justify-content: center;
  }

  .card {
    width: 100%;
  }
  .padding {
    padding: 0 8px;
  }
}

/* FOOTER */

.footer {
  margin-top: auto;
}

.footer__content {
  display: flex;
  align-items: center;
  white-space: nowrap;
  padding: 30px 0;
}

.footer__content div {
  width: 33.33%;
}

.footer__request {
  font-size: 14px;
  letter-spacing: 0.25px;
  position: relative;
  z-index: 3;
}

.footer__link-request {
  opacity: 0.5;
  position: relative;
  z-index: 3;
}

.footer__link-request:hover {
  opacity: 1;
}

.footer__madeby {
  text-align: center;
  font-size: 13px;
  letter-spacing: 0.25px;
  opacity: 0.5;
  transition: all ease 0.3s;
  position: relative;
  z-index: 3;
}

.footer__madeby:hover {
  opacity: 1;
}

.footer__link {
  position: relative;
  z-index: 3;
}

.footer__link:hover {
  opacity: 1;
}

.footer__terms {
  text-align: right;
}

.footer__terms a {
  font-size: 14px;
  opacity: 0.5;
  margin-left: 25px;
}

@media screen and (max-width: 750px) {
  .footer__content {
    flex-direction: column;
    align-items: center;
    row-gap: 10px;
  }

  .footer__request,
  .footer__madeby,
  .footer__terms {
    text-align: center;
  }

  .footer__content div {
    width: 100%;
  }

  .footer__terms a:first-child {
    margin-left: 0;
  }
}
</style>
