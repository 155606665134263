<template>
  <section class="landing-page">
    <Header />
    <Main />
    <Pricing />
    <ProductHunt />
    <Footer />

    <section class="hunt"></section>

    <footer class="footer"></footer>
  </section>
</template>

<script>
import Header from "@/components/landing/Header";
import Main from "@/components/comparison/Main";
import Pricing from "@/components/landing/Pricing";
import ProductHunt from "@/components/landing/ProductHunt";
import Footer from "@/components/landing/Footer";

export default {
  components: {
    Header,
    Main,
    Pricing,
    ProductHunt,
    Footer,
  },
  mounted() {
    const title = 'Site Palette vs. Other Browser Extensions: The Best Color Palette Tool for Designers';
    const description = 'Compare Site Palette with popular browser extensions and discover which tool offers the best palette generation, design exports, and seamless integration with your favorite design apps. Make the right choice for your design needs.';

    document.title = title;
    this.updateMetaTag('description', description);
    this.updateMetaTag('twitter:title', title);
    this.updateMetaTag('twitter:description', description);
    this.updateMetaTag('title:title', title);
    this.updateMetaTag('title:description', description);
  },
  methods: {
    updateMetaTag(name, content) {
      let metaTag = document.querySelector(`meta[name="${name}"], meta[property="${name}"]`);
      if (!metaTag) {
        metaTag = document.createElement('meta');
        if (name.startsWith('og:') || name.startsWith('twitter:')) {
          metaTag.setAttribute('property', name);
        } else {
          metaTag.setAttribute('name', name);
        }
        document.head.appendChild(metaTag);
      }
      metaTag.setAttribute('content', content);
    },
  },
};
</script>
<style scoped>

* {
  color: #3d3d3d;
}

.landing-page {
  width: 100%;
  box-sizing: border-box;
}

</style>
