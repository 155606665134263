<template>
  <section class="palettes-page">
    <header class="header">
      <div class="container" >
        <div class="header__content" :class="{'header__content-without-banner': !$store.state.isBannerShow}">
          <router-link to="/" class="header__logo">
            <img src="@/images/logo-black.svg" />
          </router-link>
          <nav class="header__navigation">
            <router-link to="/profile" class="header__link"
              >My profile</router-link
            >
          </nav>
        </div>
      </div>
    </header>

    <main class="palettes">
      <h1 class="palettes__title">My palettes</h1>
      <div class="container" :class="{'container_hide': ($store.state.palettes.length < 1)}">
        <div class="palettes__content">
          <Palette v-for="palette in $store.state.palettes" :palette="palette" :key="palette._id" @click="openOnePalette(palette._id)"/>
        </div>
      </div>
      <div class="container-empty" :class="{'container-empty_show': ($store.state.palettes.length < 1)}">
      <h2 class="container-empty__title">Still no palette</h2>
      <p class="container-empty__subtitle">Click the extension in your browser</p>
      <img class="container-empty__img" src="@/images/NoPalettes.png" />
      </div>
    </main>
    <footer class="footer">
      <div class="container">
        <div class="footer__content">
          <span class="footer__text"
            >Made by
            <a class="footer__link" href="https://karmabot.chat/" target="_blank"
              >Karma</a
            > team with ❤️</span
          >
        </div>
      </div>
    </footer>
  </section>
</template>

<script>
import Palette from "@/components/Palette";
import KarmaBot from "@/components/KarmaBot";
import mainApi from '../utils/MainApi';
import store from '@/store'

export default {
  components: {
    Palette,
    KarmaBot,
  },
  data() {
    return {
    }
  },
  computed: {
  },
  methods: {
    openOnePalette: function (paletteId) {
      this.$store.dispatch("openPalette", paletteId);
    },
  },
  beforeRouteEnter(to, from, next){
    mainApi.getPalettes(store.getters.userToken).then((data) => {
      store.dispatch('setPalettes', data)
      next()
    })
  },
};
</script>

<style scoped>
* {
  background: #fefefe;
}

.palettes-page {
  min-height: 100vh;
  width: 100%;
  box-sizing: border-box;
  padding: 0 20px;
  display:flex;
  flex-direction:column;
  min-height: 100vh;
}

.palettes__title{
  font-weight: 600;
  font-size: 36px;
  line-height: 38px;
  color: #000000;
  padding: 0;
  margin: 0 auto 32px;
  max-width: 1224px
}

.container {
  width: 100%;
  max-width: 1224px;
  margin: 0 auto;
}

.container_hide {
  display: none;
}

.container-empty{
  padding-top: 80px;
  display: none;
  flex-direction: column;
  align-items: center;
}
.container-empty_show{
  display: flex;
}

.container-empty__title{
  margin: 0;
  padding: 0;
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  color: #292C2E;
  margin-bottom: 30px;
}

.container-empty__subtitle{
  margin: 0;
  padding: 0;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #292C2E;
  opacity: 0.5;
  margin-bottom: 20px;
}

.container-empty__img{
  width: 428px;
  height: 250px;
}

/* HEADER */

.header {
}

.header__content {
  box-sizing: border-box;
  padding: 34px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header__logo {
  width: 172px;
  height: 24px;
}

.header__logo:hover {
  opacity: 0.8;
}

.header__logo img {
  width: 100%;
}

.header__navigation {
  display: flex;
  column-gap: 28px;
}

.header__link {
  color: #3080F2;
  font-weight: 900;
  font-size: 16px;
  line-height: 22px;
  box-sizing: border-box;
  padding: 0 10px;
  height: 42px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.header__link:hover {
  opacity: 0.8;
}

/* PALETTES */

.palettes {
  box-sizing: border-box;
  padding: 15px 0 26px;
  flex: 1
}

.palettes__content {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 24px;
  row-gap: 20px;
  cursor: pointer;
}

/* FOOTER */

.footer {
  bottom: 0;
  width: 100%;
}

.footer__content {
  box-sizing: border-box;
  padding: 8px 50px 27px;
  width: fit-content;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.footer__text {
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: rgba(0, 0, 0, 0.7);
  background: transparent;
}

.footer__link {
  color: rgba(0, 0, 0, 0.7);
  background: transparent;
  border-bottom: rgba(0, 0, 0, 0.4) 1px solid;
}

@media screen and (max-width: 1100px) {
  .palettes__content{
    grid-template-columns: repeat(2, minmax(330px, 390px));
    justify-content: center;
  }
}

@media screen and (max-width: 768px) {
  .palettes__content{
    grid-template-columns: repeat(1, minmax(330px, 390px));
    justify-content: center;
  }
}

@media screen and (max-width: 1000px) {
  .header__content{
    padding-top: 84px;
  }
  .header__content-without-banner{
    padding-top: 20px;
  }
}
@media screen and (max-width: 400px) {
  .container-empty__img{
    width: 275px;
    height: auto;
  }
  .footer__content{
    padding-left: 15px;
    padding-right: 15px;
  }
}
</style>
