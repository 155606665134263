<template>
  <section class="page">
    <router-view>

    </router-view>
  </section>
</template>

<script>


export default {
  components: {

  },
};
</script>

<style>

@font-face {
  font-family: 'GeneralSans';
  src: url('./fonts/GeneralSans-Regular.woff2') format('woff2');
       /* url('./fonts/GeneralSans-Regular.woff') format('woff'),
       url('./fonts/GeneralSans-Regular.ttf') format('truetype'); */
       font-weight: 400;
       font-display: swap;
       font-style: normal;
}

@font-face {
  font-family: 'GeneralSans';
  font-display: auto;
  src: url('./fonts/GeneralSans-Medium.woff2') format('woff2');
       /* url('./fonts/GeneralSans-Medium.woff') format('woff'),
       url('./fonts/GeneralSans-Medium.ttf') format('truetype'); */
       font-weight: 500;
       font-display: swap;
       font-style: normal;
}

@font-face {
  font-family: 'GeneralSans';
  font-display: auto;
  src: url('./fonts/GeneralSans-Semibold.woff2') format('woff2');
       /* url('./fonts/GeneralSans-Semibold.woff') format('woff'),
       url('./fonts/GeneralSans-Semibold.ttf') format('truetype'); */
       font-weight: 600;
       font-display: swap;
       font-style: normal;
}
* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html {
  -webkit-font-smoothing: antialiased;
  background-color: #FEFEFE;
  cursor: default;
  min-width: 320px;
  color: #fff;
  font-size: 15px;
  line-height: normal;
  font-weight: normal;
  scroll-behavior: smooth;
}

body {
  /* display: flex; */
  min-height: 100vh;
  flex-direction: column;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  align-items: center;
  justify-content: center;
  margin: 0;
  padding: 0;
  font-family: "GeneralSans", Helvetica, Arial;
}

ul {
  list-style: none;
}

.page {
  min-height: 100vh;
  height: fit-content;
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 10;
}

a {
  text-decoration: none;
  color: #fff;
  transition: all ease 0.3s;
}

a:focus {
  outline: 0 !important;
}

input::placeholder {
  color: rgba(#fff, 0.5) !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0 30px #fff inset !important;
  -webkit-text-fill-color: #000 !important;
  background-color: #000 !important;
}
</style>
